<template>
  <div>
    <h1 class="text-2xl mb-6">Set up des Rédacteurs</h1>
    <RedacForm :sites="sites" :periods="periods" @save="saveRedac"></RedacForm>

    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title>
        <h2 class="text-xl">Liste des set-up de rédacteurs</h2>
      </v-card-title>
      <v-row>
        <v-col cols="8">
          <v-text-field
            class="ml-6 mr-6 mb-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Site, Redac ou Email)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            class="ml-6 mr-6 mb-6"
            outlined
            clearable
            v-model="search_column"
            :items="['site', 'redac', 'email']"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        class="ml-6 mr-6"
        :items="filteredData"
        :headers="tableHeaders"
      >
        <template v-slot:item.price="{ item }">
          <span>{{ item.price }} €</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click="openUpdateDialog(item)"
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              @click="deleteRedac(item)"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="updateSetup">
      <v-card>
        <v-card-title>
          <h2 class="text-xl">Mise à jour du prix</h2>
        </v-card-title>
        <p class="ml-6 mr-6">
          Name: {{ redacToUpdate.redac }} / Email: {{ redacToUpdate.email }} /
          Site: {{ redacToUpdate.site }} / Période: {{ redacToUpdate.period }}
        </p>
        <v-row>
          <v-text-field
            class="ml-6 mr-6"
            v-model="price"
            label="Prix"
            outlined
          ></v-text-field>
        </v-row>
        <v-row>
          <v-btn @click="updatePrice" color="primary" class="ml-6 mr-6 mb-6"
            >Enregistrer</v-btn
          >
          <!-- <v-btn outlined color="secondary" class="ml-6 mr-6 mb-6"
            >Annuler
          </v-btn> -->
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isLastSetupsVisible" max-width="500">
      <v-card>
        <v-card-title class="headline">Redacs Setup Updated</v-card-title>
        <v-card-text>
          <div v-for="setup in lastSetups" :key="setup.id" class="setup-item">
            <p><strong>Email:</strong> {{ setup.redac_email }}</p>
            <p><strong>Site:</strong> {{ setup.redac_site }}</p>
            <p><strong>Period:</strong> {{ setup.period }}</p>
            <p><strong>Price:</strong> {{ setup.price }}</p>
            <p><strong>Action:</strong> {{ setup.action }}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="isLastSetupsVisible = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RedacForm from "@/components/audience/renta-par-article/forms/RedacsSetupForm.vue";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import axios from "@axios";
import { mapState } from "vuex";
import { saveRmraView } from "@/utils/rmr-analytics";
export default {
  name: "RedacSetup",
  components: {
    RedacForm,
  },
  data() {
    return {
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
      tableHeaders: [
        { text: "Nom", value: "redac" },
        { text: "Email", value: "email" },
        { text: "Site", value: "site" },
        { text: "Période", value: "period" },
        { text: "Prix", value: "price" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      search_column: "",
      siteSelected: null,
      redacSelected: null,
      periodSelected: null,
      redacToUpdate: {},
      price: 0,
      updateSetup: false,
      lastSetups: [],
      isLastSetupsVisible: false,
    };
  },

  created() {
    this.getSites();
    this.getPeriods();
    this.getRedacsSetup();
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {
    async openUpdateDialog(item) {
      this.updateSetup = true;
      this.redacToUpdate = item;
    },
    async updatePrice() {
      const data = {
        price: this.price,
      };
      await axios.put(`redacs/redac_setup/${this.redacToUpdate.id}`, data);
      this.getRedacsSetup();
      this.updateSetup = false;
    },

    async saveRedac(reiceivedData) {
      this.redacSelected = reiceivedData.redacSelected;
      this.periodSelected = reiceivedData.periodSelected;
      this.price = reiceivedData.price;
      const combinations = [];
      this.redacSelected.forEach((redac) => {
        this.periodSelected.forEach((period) => {
          combinations.push({ redac, period });
        });
      });
      const data = {
        combinations: combinations,
        price: this.price,
      };
      const setups = await axios.post("redacs/new_redacs_setup", data);
      this.lastSetups = setups.data;
      this.getRedacsSetup();
      this.isLastSetupsVisible = true;
      // alert(`Redac(s) setuped successfully: ${JSON.stringify(setups.data)}`);
    },
    async getPeriods() {
      const response = await axios.get("redacs/get_redacs_periods");
      this.$store.dispatch("redac/setPeriods", response.data);
    },
    async getSites() {
      const response = await axios.get("sites/");
      this.$store.dispatch("redac/setSites", response.data.items);
    },
    async getRedacsSetup() {
      const response = await axios.get("redacs/get_redacs_setup");
      this.$store.dispatch("redac/setRedacsSetup", response.data);
    },
    async deleteRedac(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        await axios.delete(`redacs/redac_setup/${item.id}`);
        this.getRedacsSetup();
      } else {
        // If the user clicks "Cancel", you can optionally handle that case here
      }
    },
  },
  computed: {
    ...mapState("redac", ["sites"]),
    ...mapState("redac", ["periods"]),
    ...mapState("redac", ["redacsSetup"]),
    filteredData() {
      if (!this.search) return this.redacsSetup;
      const searchLower = this.search.toLowerCase();

      if (!this.search_column) {
        return this.redacsSetup.filter((redac) => {
          return (
            redac.redac.toLowerCase().includes(searchLower) ||
            redac.email.toLowerCase().includes(searchLower) ||
            redac.site.toLowerCase().includes(searchLower)
          );
        });
      } else {
        return this.redacsSetup.filter((redac) => {
          return redac[this.search_column].toLowerCase().includes(searchLower);
        });
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
.setup-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.setup-item p {
  margin: 5px 0;
}
</style>
